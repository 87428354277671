import ApiBase from '@/api/base'
import {notify} from '@/helpers/notifyHelper'
import StorageHelper from '@/helpers/localStorageHelper'

class Translations 	{
	static getAllByCategory(self, currentCategory) {
		const category = {category: currentCategory}

		self.http('get', `${ApiBase.baseUrl()}/translations/category`, category, ApiBase.authHeaders())
			.then(response => {
				ApiBase.setToStore(null, 'setTranslationsAll', response.data)
			})
	}
	static setOne(self) {
		const form = self.form
		const category = self.form.category

		self.http('post', `${ApiBase.baseUrl()}/translations/create`, form, ApiBase.authHeaders())
			.then(response => {
				notify(self, 'success',  self.trans(response.data.message))
				this.getAllByCategory(self, category)
			})
	}
	static async getByCurrentLanguage(self) {
		const lang = StorageHelper.get('language') || ApiBase.defaultLang()
		const response = await self.http('get', `${ApiBase.baseUrl()}/translations/${lang}`)
		ApiBase.setToStore(null, 'setTranslationsList', response.data)
	}
	static updateOne(self, key) {
		const form = self.form
		const category = self.currentCategory

		self.http('put', `${ApiBase.baseUrl()}/translations/${key}`, form, ApiBase.authHeaders())
			.then(response => {
				this.getAllByCategory(self, category)
				notify(self, 'success',  self.trans(response.data.message))
			})
	}
	static deleteOne(self, key) {
		const category = self.currentCategory
		self.http('delete', `${ApiBase.baseUrl()}/translations/delete/${key}`, null, ApiBase.authHeaders())
			.then(response => {
				this.getAllByCategory(self, category)
				notify(self, 'success', self.trans(response.data.message))
			})
	}
}

export default Translations
